/* /App.css v1 */
.App {
  text-align: left;
}
.version {
  color: #ffffff;
}
.javi {
  padding-top: 20px;
  width: 100%;
  padding: 20px;
  margin-right: 20px;
}
.javiimage {
  flex-grow: 1;
  max-width: 200px;
  margin: 0 auto;
  position:relative;
  z-index: 2;
}

.App-logo {
  height: 2vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}
.onebytwo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin:0 10px;
  border-radius: 2px;
}
.onebytwo-one {
  flex-grow: 1;
  /*background-color: red; */
  flex-flow: row;
  padding: 2px;
  justify-content: flex-start;
}
.App-link {
  color: var(--bluegreen-txt) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 640px) {
  .javiimage {
    max-width: 300px;
    margin: 0;
  }
}