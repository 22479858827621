/* 
  Reset 
  src/styles/App.css v1 
*/
body,
html {
  font-family: OpenSans, Helvetica, Arial, sans-serif;
  color: #444;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  line-height: 1;
  font-family: sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
button,
code,
input,
optgroup,
option,
pre,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
}
ol,
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: EnergySoft, Helvetica, Arial, sans-serif;
}
* {
  scroll-behavior: smooth !important;
}
/* site variables */
html {
  --main-txt-color: #31343d;
  --bluegreen-bg: #92cccd;
  --header-bg: #31343d;
  --bluegreen-txt: #92cccd;
  --darkblue-bg: #14284e;
  --footer-bg: #31343d;
  --light-bg: #f4f4f4;
  --dark-bluegreen-txt: #268788;
  --navlinks: #61dafb;
  --lightgray-bg: #bfcccf;
}

::selection {
  background: var(--bluegreen-bg);
  color: var(--darkblue-bg);
}
/* start of the main styles */
html {
  scroll-behavior: smooth;
  background-color: var(--header-bg);
}
body {
  font-size: 1rem;
  color: var(--main-txt-color);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background: var(--header-bg);
}
a {
  color: var(--navlinks);
}
p {
  margin-bottom: 14px;
}
/* header */
header {
  display: flex;
  justify-content: space-between;
  background: var(--header-bg);
  padding: 1% 0;
  align-items: center;
  flex-direction: column;
}
main {
  display: flex;
  justify-content: space-between;
  background: #f4f4f4;
  padding: 1% 0;
  align-items: center;
  flex-direction: column;
}
#home a {
  text-decoration: none;
}
.logo {
  color: var(--darkblue-bg);
  font-size: 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  background: var(--bluegreen-bg);
  text-decoration: none;
}
.logo a,
.logo a:link,
.logo a:visted,
.logo:link {
  text-decoration: none !important;
}

.language {
  font-size: 1.2rem;
  color: var(--bluegreen-txt);
  border-bottom: 4px var(--bluegreen-bg) solid;
  padding: 0 1rem;
  text-decoration: none;
  cursor: pointer;
}
/* main */
main {
  /* width: 95%;
  margin: 0 auto; */
}
.home {
  /* background-image: url("./../images/brainstorming.jpeg"); */
  background-size: cover;
  background-blend-mode: multiply;
}
#home a {
  text-decoration: none;
  width:100%;
  margin-top:0;
}
/* about me section */
#about-me,
#work,
#contact-me {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  padding: 0.5%;
}
.side {
  width: 100%;
  display: flex;
  flex-grow: 2;
  background: #f4f4f4;
  padding: 3% 3% 3% 5%;
  justify-content: flex-start;
}
.side h2 {
  font-size: 2.5rem;
  color: var(--darkblue-bg);
  text-align: right;
  line-height: 1.2;
}
.content {
  flex: 9;
  background: #f4f4f4;
  padding: 2%;
  border-left: none;
  border-top: 0.3rem var(--darkblue-bg) solid;
  line-height: 1.4;
  width: 100%;
}
/* Work */
#work {
  padding: 0;
  margin: 0;
}
#work .content {
  border-left: none;
  border-top: 0.3rem var(--darkblue-bg) solid;
}
.project:nth-child(1) {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
}
#work .content a {
  align-self: flex-end;
  position: relative;
  z-index: 5;
  color: var(--main-txt-color);
  text-decoration: none;
}
#work .content a:visited {
  color: var(--main-txt-color);
  text-decoration: none;
}
.project-title {
  align-self: flex-end;
  position: relative;
  background: var(--bluegreen-bg);
  padding: 1em;
  margin-bottom: 1em;
  z-index: 5;
}
.hide {
  display:none;
}
.show {
  display:block;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkblue-bg);
  opacity: 0.8;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

.overlay:hover {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.project {
  position: relative;
  width: 100%;
  margin: 1%;
  border: 0.2em var(--bluegreen-bg) solid;
  display: flex;
  flex-direction: row;
  min-height: 9em;
}
/* Contact Me */
.contact-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.left,
.right {
  width: 100%;
  padding: 3% 3% 3% 5%;
  justify-content: flex-start;
}
.left {
  flex: 1;
  width: 100%;
  border-left: 1px #268788 solid;
  background-color: #acd4d5;
}
#contact-me {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#contact-me a,
#contact-me a:visited {
  font-size: 1.2rem;
  color: var(--darkblue-bg);
  border-bottom: 2px var(--darkblue-bg) solid;
  padding: 0 1rem;
  text-decoration: none;
  align-self: center;
  display: flex;
}
#contact-me .content {
  text-align: left;
}

form input[type="text"],
form input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  text-align: left;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
}
.hide-sm{display:none;}
label{ width:100%; }
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #f4f4f4;
  box-shadow: 0 0 8px #268788;
}
form button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
}
/* footer */
footer {
  background: var(--footer-bg);
  display: flex;
  flex-direction: row;
  padding: 2%;
  justify-content: center;
}
footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
footer .disclaimer {
  padding-top: 10px;
  line-height: 15px;
  font-size: 12px;
  color: var(--dark-bluegreen-txt);
  text-align: left;
}
footer li a {
  font-size: 0.9rem;
  color: #92cccd;
  padding: 0 1rem;
  text-decoration: none;
}

#blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.border-top {
  border-top: 14px solid #f30000 !important;
}
.card {
  width:100%;
  color: var(--main-txt-color);
  background: transparent;
  box-shadow: 0 0 8px #d6d6d6;
  margin-bottom:10px;
  min-height: 300px;
 
}
.card .footerJav { padding: 20px; text-align: center; text-decoration: none; background: var(--bluegreen-bg)}
.card .footerJav a { text-decoration: none}
.card .footerJav a:after { content: " → "}
.card a, .card a:visited {
  color: var(--main-txt-color);
}
.card a:hover {
  color: var(--header-bg);
  font-weight: bold;
}
.w50 {
  width: 50%;
  padding: 0 10px;
}
.w33 {
  width:100%;
  padding: 0 10px;
  min-height: 250px;
}
.onebytwo {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.introduction {
  /* margin: auto 0; */
  width: 100%;
  font-size:20px;
  line-height: 20px;
  padding:20px;

  background-color: var(--lightgray-bg);
  position:relative;
  margin-top: -50px;
  padding-top: 43px;
}
nav ul {
  display: flex;
  justify-content: space-between;
  min-width: 320px ;
  flex-direction: column;
}
nav {
  padding-right: 6%;
  width: 100%;
}
nav:last-child{
  padding-right: 0;
}

nav li {
  padding: 0.5em;
}
nav li a { width: 100%;}
nav a {
  font-size: 1.2rem;
  color: var(--bluegreen-txt);
  xborder-bottom: 4px var(--bluegreen-bg) solid;
  padding: 0 1rem;
  text-decoration: none;
}
@media (min-width: 640px) {
  nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 200px;
  }
  nav {
    padding-right: 6%;
    width: auto;
  }
 
  .introduction {
    /* margin: auto 0; */
    width: 50%;
    font-size:32px;
    line-height: 40px;
    background-color: var(--light-bg);
    position:relative;
    margin-top: -20px;
    padding-top: 43px;
  }
  .onebytwo {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .w33 {
    width:50%;
  }
  header {
    flex-direction: row;
  }
  /* main {
    width: 90%;
  } */
  nav {
    padding-right: 6%;
    display: inline-block;
  }
  .side {
    width: 4em;
    justify-content: flex-end;
  }
  .content {
    border-left: 0.3rem var(--darkblue-bg) solid;
    border-top: none;
    padding: 2% 2% 2% 4%;
  }
  #about-me,
  #work,
  #contact-me {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    padding: 0;
  }
  #work .content {
    flex: 9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #f4f4f4;
    padding: 2% 2% 2% 4%;
    border-top: none;
    border-left: 0.3rem var(--darkblue-bg) solid;
    line-height: 1.4;
  }
  .project {
    width: 48%;
  }
  #contact-me a,
  #contact-me a:visited {
    display: inline-block;
  }
}
@media (min-width:480px){
  #home a {
    text-decoration: none;
    width: auto;
    margin-top:0;
  }
  #blog {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding:10px;
  }
  .w33 {
    width:33%;
  }
  .left,
.right {
  width: 50%;
  padding: 3% 3% 3% 5%;
  justify-content: flex-start;
} 
.hide-sm{display:block;}
}
